import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {
    condition: {},
  },
  getters: {
    getCondition(state){
      return state.condition
    }
  },
  mutations: {
    resetCondition(state){
      for (let conditionKey in state.condition) {
        state.condition[conditionKey] = undefined
      }
    }
  },
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/presalesreturnbound/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/presalesreturnbound/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/app/presalesreturnbound/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    delItem (ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/app/presalesreturnbounditem/delItem', params).
        then(response => resolve(response)).
        catch(error => reject(error))
      })
    },

    delAllItem (ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/app/presalesreturnbounditem/delAllItem', params).
        then(response => resolve(response)).
        catch(error => reject(error))
      })
    },
    //根据当前人获取团队列表
    loadStoreList (ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/app/storesales/getStoreByUserId', params).
        then(response => resolve(response)).
        catch(error => reject(error))
      })
    },
    //根据团队获取所有业务员
    loadSalesManList (ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/app/store/getUserByStoreId', params).
        then(response => resolve(response)).
        catch(error => reject(error))
      })
    },
    //根据团队获取所有客户
    loadChannelList (ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/app/company/search', params).
        then(response => resolve(response)).
        catch(error => reject(error))
      })
    },
    //根据团队获取所有仓库
    loadWarehouseList (ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/app/warehouse/findWareHouseIdsByStoreIds', params).
        then(response => resolve(response)).
        catch(error => reject(error))
      })
    },
    loadProductList (ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/app/stock/searchSalesReturnBoundProduct', params).
        then(response => resolve(response)).
        catch(error => reject(error))
      })
    },
    addItem (ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/app/presalesreturnbounditem/addItem', params).
        then(response => resolve(response)).
        catch(error => reject(error))
      })
    },
    updateItem (ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/app/presalesreturnbounditem/updateItem', params).
        then(response => resolve(response)).
        catch(error => reject(error))
      })
    },
    addItemMemo (ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/app/presalesreturnbounditem/addMemo', params).
        then(response => resolve(response)).
        catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/app/presalesreturnbound/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    submit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/app/presalesreturnbound/submit', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/presalesreturnbound/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    changeStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/presalesreturnbound/changeStatus', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    checkSuccess(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/presalesreturnbound/checkSuccess', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    cancelAudit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/presalesreturnbound/cancelAudit', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    addAttachments(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/presalesreturnbound/addAttachments', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    batchReturnBound(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/presalesreturnbound/batchReturnBound', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    exportPreSalesReturnboundData(ctx, params) {
      return new Promise((resolve, reject) => {
        axios({
              url: '/api/exportexcelfile/exportPreSalesReturnboundData',
              method:'get',
              params: params,
              responseType: 'blob',
            }
        )
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    searchReturnData(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/presalesreturnbound/searchReturnData', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },



  },
}
