import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty,isNumber} from "@core/utils/filter";

export default function presalesreturnboundUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [

    { key: 'order_no', label: '单据编号' },
    { key: 'so_add_time', label: '单据创建日期' },
    { key: 'so_sales_time', label: '销售/退货业务日期' },
    { key: 'code', label: '69码' },
    { key: 'name', label: '商品名称'},
    { key: 'label_name', label: '商品标签'},
    { key: 'box_quantity', label: '单份规格数量'},
    { key: 'so_qty', label: '销售数量' },
    { key: 'return_qty', label: '退货数量' },
    { key: 'pre_return_qty', label: '前置待退数量' },
    { key: 'one_qty', label: '换算成单瓶数量' },
  ]


  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const canReturnQty = ref(0)
  const searchKey = ref('')
  const orderBy = ref('product_id')
  const isSortDirDesc = ref(true)
  const condition = ref({
    storeName:"",
    storeId:"",
    channelName:"",
    channelId:"",
    searchInput:"",
    isTurnOn:"是",
    productId:'',
    productName:'',

  })

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const params = {
      search_key: searchKey.value,
      start: start.value,
      limit: limit.value,
      order_by: orderBy.value,
      storeId:isNumber(condition.value.storeId)>0?condition.value.storeId:config.store_id,
      channelId:isNumber(condition.value.channelId)>0?condition.value.channelId:config.channel_id,
      productId:isNumber(condition.value.productId)>0?condition.value.productId:config.product_id,
      searchInput:condition.value.searchInput,
      isTurnOn:condition.value.isTurnOn==='是'?'是':condition.value.isTurnOn.label,
      order_desc: isSortDirDesc.value === true ? 'desc':'',
    }
    store
      .dispatch('presalesreturnbound/searchReturnData', params)
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        canReturnQty.value = data.ext.can_return_qty
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    refetchData,
    dataMeta,
    condition,
    listTotals: listTotals,
    canReturnQty,
    isNumber

    // Extra Filters
  }
}
