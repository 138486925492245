<template>

  <div>

    <!-- Table Container Card -->
    <b-card
            no-body
            class="mb-0"
    >

      <div class="m-2">
        <b-card

        >
          <b-form
                  @submit.prevent="refetchDatas"
                  @reset.prevent="resetCondition"
          >
            <b-row>
              <!--终端客户-->
              <b-col
                      cols="12"
                      md="4"
                      class="mb-2"
              >
                <xy-input-button
                        label="终端客户"
                        type="input"
                        v-on:change="fromChildren($event,['channelName','channelId'])"
                        :params="['channelName','channelId']"
                        :value="condition.channelName"
                        modalName="终端客户"
                        placeholder="点击选择终端客户 "
                >
                </xy-input-button>
              </b-col>
              <!--店铺团队-->
              <b-col
                      cols="12"
                      md="4"
                      class="mb-2"
              >
                <xy-input-button
                        label="店铺团队"
                        type="input"
                        v-on:change="fromChildren($event,['storeName','storeId'])"
                        :params="['storeName','storeId']"
                        :value="condition.storeName"
                        modalName="店铺"
                        placeholder="点击选择店铺 "
                >
                </xy-input-button>
              </b-col>

              <!--商品名称/69码-->
              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="关键字:"
                        label-for="finance_id"
                        label-size="sm"

                >
                  <b-form-input
                          v-model="condition.searchInput"
                          name="finance_id"
                          class="d-inline-block mr-1"
                          size="sm"
                          placeholder="商品名称/69码"
                  />
                </b-form-group>
              </b-col>

              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="开启标签"
                        label-for="isTurnOn"
                        label-size="sm"
                        class="mb-1"
                >
                    <v-select
                            id="isTurnOn"
                            :options="getCodeOptions('yesno')"
                            :clearable="true"
                            v-model="condition.isTurnOn"
                            class="select-size-sm "
                    >
                    </v-select>
                </b-form-group>
              </b-col>

              <b-col
                      cols="12"
                      md="4"
                      class="mb-2"
              >
                <xy-input-button
                        label="商品"
                        type="input"
                        v-on:change="fromChildren($event,['productName','productId'])"
                        :params="['productName','productId']"
                        :value="condition.productName"
                        modalName="商品"
                        placeholder="点击选择商品 "
                >
                </xy-input-button>
              </b-col>


              <b-col
                      cols="12"
                      md="12"
                      class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                    v-model="limit"
                    :options="limitOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>
        </b-row>

      </div>

      <b-table
              ref="refListTable"
              class="position-relative"
              :items="searchList"
              responsive
              hover
              small
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="orderBy"
              show-empty
              empty-text="未找到记录"
              :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(code)="data">
          {{ data.item.ext.code}}
        </template>



        <template #cell(name)="data">
          {{ data.item.ext.name}}
        </template>

        <template #cell(box_quantity)="data">
          {{ data.item.ext.box_quantity}}
        </template>

        <template #cell(so_qty)="data">
          {{ data.item.ext.so_qty}}
        </template>

        <template #cell(return_qty)="data">
          {{ data.item.ext.return_qty}}
        </template>

        <template #cell(pre_return_qty)="data">
          {{ data.item.ext.pre_return_qty}}
        </template>

        <template #cell(one_qty)="data">
          {{ data.item.ext.one_qty}}
        </template>

        <template #cell(label_name)="data">
          {{ data.item.ext.label_name}}
        </template>

        <template #cell(so_add_time)="data">
          {{ isEmpty(data.item.ext.so_add_time)?"":toTime(data.item.ext.so_add_time)}}
        </template>

        <template #cell(so_sales_time)="data">
          {{ isEmpty(data.item.ext.so_sales_time)?"":toDate(data.item.ext.so_sales_time)}}
        </template>


      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <span class="text-muted">可退小计：{{canReturnQty}}</span>
            <b-pagination
                    v-model="start"
                    :total-rows="listTotals"
                    :per-page="limit"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
  import {ref, onUnmounted, reactive, onMounted, toRefs} from '@vue/composition-api'
  import {
    avatarText,
    toTime,
    toDate,
    getCodeOptions,
    getCode,
    getCodeLabel,
    getCodeColor,
    isEmpty,
    isNumber
  } from '@core/utils/filter'
  import XyInputButton from "@/views/components/xy/XyInputButton";
  import {useToast} from "vue-toastification/composition";
  import presalesreturnboundStore from './presalesreturnboundStore'
  import presalesreturnboundReturnUseList from "@/views/apps/presalesreturnbound/presalesreturnboundReturnUseList";
  import {getUserData} from "@/auth/utils";
  import {useRouter} from "@core/utils/utils";


  export default {
    components: {
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      vSelect,
      XyInputButton
    },
    methods: {
    },
    data() {
      return {
      }
    },
    setup() {
      if (!store.hasModule('presalesreturnbound')) store.registerModule('presalesreturnbound', presalesreturnboundStore)


      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule('presalesreturnbound')) store.unregisterModule('presalesreturnbound')
      })
      const toast = useToast()

      const fromChildren = function (params, modal) {
        for (let i = 0; i < modal.length; i++) {
          this.condition[modal[i]] = params == null ? null : params[modal[i]]
        }
        this.$forceUpdate()
      }
      const  resetCondition = function(){
        this.condition = {}
        this.refetchData();
      }

      const  refetchDatas = function(){
        if(isEmpty(this.condition.storeId)||isEmpty(this.condition.channelId)){
          toast.error("请选择团队/客户")
        }else{
          this.start =  isNumber(1);
          this.refetchData();
        }
      }
      const router = useRouter()

      const {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        orderBy,
        isSortDirDesc,
        refListTable,
        refetchData,
        condition,
        canReturnQty

        // UI
      } = presalesreturnboundReturnUseList({
        product_id: router.route.value.query.product_id,
        store_id: router.route.value.query.store_id,
        channel_id: router.route.value.query.channel_id,
      })

      return {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals,
        dataMeta,
        canReturnQty,
        limitOptions,
        searchKey,
        orderBy,
        isSortDirDesc,
        refListTable,
        refetchData,

        // Filter
        avatarText,
        getCodeOptions,
        getCode,
        getCodeLabel,
        getCodeColor,
        // UI
        toTime,
        toDate,fromChildren,isEmpty,condition,resetCondition,refetchDatas,isNumber
      }
    },
  }
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
